<template>
  <div>
    <template>
      <section id="home" class="primary">
        <v-container>
          <v-layout
            column
            flex
            align-center
            justify-center
            style="min-height: 100vh"
          >
            <v-flex xs12>
              <v-card flat class="primary text-center">
                <div
                  class="display-2 hidden-sm-and-up white--text font-weight-bold mb-4"
                >
                  404 Page Not Found
                </div>
                <div
                  class="display-4 hidden-xs-only white--text font-weight-bold mb-4"
                >
                  404 Page Not Found
                </div>
                <div
                  class="subheading hidden-sm-and-up white--text font-weight-bold mb-4"
                >
                  aejMIS - Management Information Reporting
                </div>
                <div
                  class="display-2 hidden-xs-only white--text font-weight-thin mb-4"
                >
                  aejMIS - Management Information Reporting
                </div>
                <v-btn color="black--text accent" to="/">
                  <v-icon class="mr-2" medium>mdi-arrow-left-bold</v-icon>Go
                  Back
                </v-btn>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
    </template>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
